/**
 * Created by emaxe on 17.08.2016.
 */

app.factory('rDay',['$resource', 'Config', function($resource, Config){
    return $resource('res/day/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        'update': { method:'PUT' },
        'where': {
            method:'POST',
            url: 'res/day/where',
        },
    });
}]);

app.factory('rRole',['$resource', 'Config', function($resource, Config){
    return $resource('res/role/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        'update': { method:'PUT' },
        'create': {
            method:'GET',
            url: 'res/role/create',
        },
        'delete': {
            method:'POST',
            url: 'res/role/destroy',
        },
        'where': {
            method:'POST',
            url: 'res/role/where',
        },
        'detach': {
            method:'POST',
            url: 'res/role/detach',
        },
        'attach': {
            method:'POST',
            url: 'res/role/attach',
        },
    });
}]);

app.factory('rPermission',['$resource', 'Config', function($resource, Config){
    return $resource('res/permission/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        'update': { method:'PUT' },
        'create': {
            method:'GET',
            url: 'res/permission/create',
        },
        'delete': {
            method:'POST',
            url: 'res/permission/destroy',
        },
    });
}]);

app.factory('rDayType',['$resource', 'Config', function($resource, Config){
    return $resource('res/day-type/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        'update': { method:'PUT' }
    });
}]);

app.factory('rDiscipline',['$resource', 'Config', function($resource, Config){
    return $resource('res/discipline/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        'update': { method:'PUT' },
        'create': {
            method:'GET',
            url: 'res/discipline/create',
        },
        'where': {
            method:'POST',
            url: 'res/discipline/where',
        },
        'getBySchoolClass': {
            method:'POST',
            url: 'res/discipline/get-by-school-class',
        },
        'getBindings': {
            method:'POST',
            url: 'res/discipline/get-bindings',
        },
        'chBindings': {
            method:'POST',
            url: 'res/discipline/ch-bindings',
        }
    });
}]);

app.factory('rSchoolClass',['$resource', 'Config', function($resource, Config){
    return $resource('res/school-class/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        update: { method:'PUT' },
        where: {
            method:'POST',
            url: 'res/school-class/where',
        },
        getCount: {
            method:'POST',
            url: 'res/school-class/get-count',
        },
        create: {
            method:'GET',
            url: 'res/school-class/create',
        },
        attachSchool: {
            method:'POST',
            url: 'res/school-class/attach-school',
        },
        getChildrenCount: {
            method:'POST',
            url: 'res/school-class/get-children-count',
        },
        delete: {
            method:'POST',
            url: 'res/school-class/destroy',
        },
    });
}]);

app.factory('rSchoolGroup',['$resource', 'Config', function($resource, Config){
    return $resource('res/school-group/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        update: { method:'PUT' },
        where: {
            method:'POST',
            url: 'res/school-group/where',
        },
        create: {
            method:'GET',
            url: 'res/school-group/create',
        },
    });
}]);

app.factory('rSchool',['$resource', 'Config', function($resource, Config){
    return $resource('res/school/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        update: { method:'PUT' },
        where: {
            method:'POST',
            url: 'res/school/where',
        },
        getCount: {
            method:'POST',
            url: 'res/school/get-count',
        },
        attachClass: {
            method:'POST',
            url: 'res/school/attach-class',
        },
        create: {
            method:'GET',
            url: 'res/school/create',
        },
        delete: {
            method:'POST',
            url: 'res/school/destroy',
        },
        addGroups: {
            method:'POST',
            url: 'res/school/add-groups',
        },
        syncGroups: {
            method:'POST',
            url: 'res/school/sync-groups',
        },
    });
}]);

app.factory('rShedule',['$resource', 'Config', function($resource, Config){
    return $resource('res/shedule/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        'update': { method:'PUT' },
        'where': {
            method:'POST',
            url: 'res/shedule/where',
        },
        'create': {
            method:'GET',
            url: 'res/shedule/create',
        },
        'generate': {
            method:'POST',
            url: 'res/shedule/generate',
        },
        'delete': {
            method:'POST',
            url: 'res/shedule/destroy',
        },
        'activate': {
            method:'POST',
            url: 'res/shedule/activate',
        },
        'getActive': {
            method:'POST',
            url: 'res/shedule/get-active',
        },
        'getDnevnik': {
            method:'POST',
            url: 'res/shedule/get-dnevnik',
        },
        'uploadXls': {
            method:'POST',
            url: 'res/shedule/upload-xls',
        },
    });
}]);

app.factory('rSheduleDay',['$resource', 'Config', function($resource, Config){
    return $resource('res/shedule-day/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        'update': { method:'PUT' },
        'where': {
            method:'POST',
            url: 'res/shedule-day/where',
        },
    });
}]);

app.factory('rSheduleDiscipline',['$resource', 'Config', function($resource, Config){
    return $resource('res/shedule-discipline/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        'update': { method:'PUT' },
        'create': {
            method:'GET',
            url: 'res/shedule-discipline/create',
        },
        'where': {
            method:'POST',
            url: 'res/shedule-discipline/where',
        },
    });
}]);

app.factory('rSheduleState',['$resource', 'Config', function($resource, Config){
    return $resource('res/shedule-state/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        'update': { method:'PUT' }
    });
}]);

app.factory('rUser',['$resource', 'Config', function($resource, Config){
    return $resource('res/user/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        'update': { method:'PUT' },
        'current': {
            method:'POST',
            url: 'res/user/current',
        },
        'attachRole': {
            method:'POST',
            url: 'res/user/attach-role',
        },
        'detachRole': {
            method:'POST',
            url: 'res/user/detach-role',
        }
    });
}]);

app.factory('rPerson',['$resource', 'Config', function($resource, Config){
    return $resource('res/person/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        update: { method:'PUT' },
        where: {
            method:'POST',
            url: 'res/person/where',
        },
        create: {
            method:'GET',
            url: 'res/person/create',
        },
        delete: {
            method:'POST',
            url: 'res/person/destroy',
        },
        addClass: {
            method:'POST',
            url: 'res/person/add-class',
        },
        removeClass: {
            method:'POST',
            url: 'res/person/remove-class',
        },
        addDiscipline: {
            method:'POST',
            url: 'res/person/add-discipline',
        },
        removeDiscipline: {
            method:'POST',
            url: 'res/person/remove-discipline',
        },
        addGroup: {
            method:'POST',
            url: 'res/person/add-group',
        },
        removeGroup: {
            method:'POST',
            url: 'res/person/remove-group',
        },
        addChild: {
            method:'POST',
            url: 'res/person/add-child',
        },
        removeChild: {
            method:'POST',
            url: 'res/person/remove-child',
        },
        reLogin: {
            method:'POST',
            url: 'res/person/re-login',
        },
        reNew: {
            method:'POST',
            url: 'res/person/re-new',
        },
        getChildren: {
            method:'POST',
            url: 'res/person/get-children',
        },
    });
}]);

app.factory('rYearShedule',['$resource', 'Config', function($resource, Config){
    return $resource('res/year-shedule/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        'update': { method:'PUT' },
        'where': {
            method:'POST',
            url: 'res/year-shedule/where',
        },
        'create': {
            method:'GET',
            url: 'res/year-shedule/create',
        },
        'generate': {
            method:'POST',
            url: 'res/year-shedule/generate',
        },
        'getCalendar': {
            method:'POST',
            url: 'res/year-shedule/get-calendar',
        },
        'getSeasons': {
            method:'POST',
            url: 'res/year-shedule/get-seasons',
        },
        'getJournal': {
            method:'POST',
            url: 'res/year-shedule/get-journal',
        },
        'getJournal0': {
            method:'POST',
            url: 'res/year-shedule/get-journal0',
        },
        'getHomeWorks': {
            method:'POST',
            url: 'res/year-shedule/get-home-works',
        },
        'getTP': {
            method:'POST',
            url: 'res/year-shedule/get-t-p',
        },
        'copyPeriods': {
            method:'POST',
            url: 'res/year-shedule/copy-periods',
        },
    });
}]);

app.factory('rYearSheduleSeason',['$resource', 'Config', function($resource, Config){
    return $resource('res/year-shedule-season/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        'update': { method:'PUT' },
        'create': {
            method:'GET',
            url: 'res/year-shedule-season/create',
        },
        'syncDays': {
            method:'POST',
            url: 'res/year-shedule-season/sync-days',
        },
        'clearDays': {
            method:'POST',
            url: 'res/year-shedule-season/clear-days',
        },
    });
}]);

app.factory('rYearSheduleItem',['$resource', 'Config', function($resource, Config){
    return $resource('res/year-shedule-item/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        'update': { method:'PUT' }
    });
}]);

app.factory('rGrade',['$resource', 'Config', function($resource, Config){
    return $resource('res/grade/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        'update': { method:'PUT' },
        'create': {
            method:'GET',
            url: 'res/grade/create',
        },
        'destroy': {
            method:'POST',
            url: 'res/grade/destroy',
        },
    });
}]);

app.factory('rHomeWork',['$resource', 'Config', function($resource, Config){
    return $resource('res/home-work/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        'update': { method:'PUT' },
        'create': {
            method:'GET',
            url: 'res/home-work/create',
        },
        'destroy': {
            method:'POST',
            url: 'res/home-work/destroy',
        },
    });
}]);

app.factory('rTP',['$resource', 'Config', function($resource, Config){
    return $resource('res/thematic-planing/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        'update': { method:'PUT' },
        'create': {
            method:'GET',
            url: 'res/thematic-planing/create',
        },
        'destroy': {
            method:'POST',
            url: 'res/thematic-planing/destroy',
        },
        'where': {
            method:'POST',
            url: 'res/thematic-planing/where',
        },
    });
}]);

app.factory('rTeacherBinding',['$resource', 'Config', function($resource, Config){
    return $resource('res/teacher-binding/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        'update': { method:'PUT' },
        'create': {
            method:'GET',
            url: 'res/teacher-binding/create',
        },
        'destroy': {
            method:'POST',
            url: 'res/teacher-binding/destroy',
        },
        'where': {
            method:'POST',
            url: 'res/teacher-binding/where',
        },
    });
}]);

app.factory('rReport',['$resource', 'Config', function($resource, Config){
    return $resource('res/report/:id', {id:'@id'}, {
        o1: {
            method:'POST',
            url: 'res/report/o1',
        },
        o2: {
            method:'POST',
            url: 'res/report/o2',
        },
        o3: {
            method:'POST',
            url: 'res/report/o3',
        },
        o4: {
            method:'POST',
            url: 'res/report/o4',
        },
        o5: {
            method:'POST',
            url: 'res/report/o5',
        },
        o6: {
            method:'POST',
            url: 'res/report/o6',
        },
    });
}]);

app.factory('rProfile',['$resource', 'Config', function($resource, Config){
    return $resource('res/profile/:id', {id:'@id'}, {
        updateMy: {
            method:'POST',
            url: 'res/profile/update-my',
        },
        getMy: {
            method:'POST',
            url: 'res/profile/get-my',
        },
        updateChild: {
            method:'POST',
            url: 'res/profile/update-child',
        },
        getChild: {
            method:'POST',
            url: 'res/profile/get-child',
        },
        getCard: {
            method:'POST',
            url: 'res/profile/get-card',
        },
    });
}]);

app.factory('rIO',['$resource', 'Config', function($resource, Config){
    return $resource('res/in_out/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        'update': { method:'PUT' },
        'where': {
            method:'POST',
            url: 'res/in_out/where',
        },
        'getMyStat': {
            method:'POST',
            url: 'res/in_out/get-my-stat',
        },
        'getChildrenStat': {
            method:'POST',
            url: 'res/in_out/get-children-stat',
        },
    });
}]);

app.factory('rBalance',['$resource', 'Config', function($resource, Config){
    return $resource('res/balance/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        update: { method:'PUT' },
        getMy: {
            method:'POST',
            url: 'res/balance/get-my',
        },
        getChildren: {
            method:'POST',
            url: 'res/balance/get-children',
        },
        getAppendUrl: {
            method:'POST',
            url: 'res/balance/get-append-url',
        },
    });
}]);

app.factory('rCard',['$resource', 'Config', function($resource, Config){
    return $resource('res/card/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        where: {
            method:'POST',
            url: 'res/card/where',
        },
        create: {
            method:'POST',
            url: 'res/card/create',
        },
        update: {
            method:'POST',
            url: 'res/card/update',
        },
        delete: {
            method:'POST',
            url: 'res/card/delete',
        },
        setActive: {
            method:'POST',
            url: 'res/card/set-active',
        },
        setLost: {
            method:'POST',
            url: 'res/card/set-lost',
        },
        restoreRequest: {
            method:'POST',
            url: 'res/card/restore-request',
        },
    });
}]);

app.factory('rCardRestore',['$resource', 'Config', function($resource, Config){
    return $resource('res/card_restore/:id', {id:'@id'}, {
        query: {
            method: 'GET',
            isArray: false
        },
        where: {
            method:'POST',
            url: 'res/card_restore/where',
        },
        create: {
            method:'POST',
            url: 'res/card_restore/create',
        },
        update: {
            method:'POST',
            url: 'res/card_restore/update',
        },
        delete: {
            method:'POST',
            url: 'res/card_restore/delete',
        },
        getMy: {
            method:'POST',
            url: 'res/card_restore/get-my',
        },
        getStates: {
            method:'POST',
            url: 'res/card_restore/get-states',
        },
    });
}]);