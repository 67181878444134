/**
 * Created by emaxe on 08.08.2016.
 */

var app = angular.module('app', [
    'ngResource',
    'oc.lazyLoad',
    'ui.tinymce',
    'md.data.table',
    'ngMaterial',
    'ngAnimate',
    'RecursionHelper',
    'toastr',
    'ngFileUpload',
    // 'datePicker'
    //'angularModalService'
], function($interpolateProvider) {
    $interpolateProvider.startSymbol('{#');
    $interpolateProvider.endSymbol('#}');
});

app.config(function($sceDelegateProvider, $mdIconProvider, $httpProvider, toastrConfig, $locationProvider) {

    angular.extend(toastrConfig, {
        autoDismiss: false,
        containerId: 'toast-container',
        maxOpened: 0,
        newestOnTop: true,
        positionClass: 'toast-bottom-right',
        preventDuplicates: false,
        preventOpenDuplicates: false,
        target: 'body'
    });

    $locationProvider.hashPrefix('');

    $sceDelegateProvider.resourceUrlWhitelist([
        'self',
        'http://admin.ss.dev/**',
        'http://ss.dev/**',
        'http://admin.ss.school-system.ru.ru/**',
        'http://ss.school-system.ru/**',
    ]);
    var csrfToken = $('meta[name=_token]').attr('content');
    $httpProvider.defaults.headers.common['X-CSRF-Token'] = csrfToken;
    $httpProvider.defaults.headers.post['X-CSRF-Token'] = csrfToken;
    $httpProvider.defaults.headers.put['X-CSRF-Token'] = csrfToken;
    $httpProvider.defaults.headers.patch['X-CSRF-Token'] = csrfToken;

    $httpProvider.defaults.headers.common['Content-Type']= 'application/json; charset=UTF-8';
    $httpProvider.defaults.headers.common['App']= 'ng';
});

app.factory('app', function($ocLazyLoad, Storage, Config){
    console.log('app is init!');
    var that = this;

    this.options = {
        mainUrl: Config.mainUrl,
    };

    moment.locale('ru');

    this.getJS = function(name,f){
        console.log('getJS - INDEX OF => ', Storage.jsLoadArray.indexOf(name));
        if(Storage.jsLoadArray.indexOf(name)<0){
            Storage.fLoad = true;
            console.log('getJS - try load => ', 'js/'+name+'.js');
            $ocLazyLoad.load('js/'+name+'.js').then(function() {
                console.log('LL - success1!', Storage.jsLoadArray);
                Storage.jsLoadArray.push(name);
                Storage.fLoad = false;
                console.log('LL - success2!', Storage.jsLoadArray);
                if(typeof(f)=='function'){
                    f(name);
                }
            }, function(e) {
                console.log(e);
            })
        }else{
            if(typeof(f)=='function'){
                f();
            }
        }
    };


    return this;
});

Array.prototype.pushIfNot = function(e){
    if(this.indexOf(e)<0){
        this.push(e);
    }
};

Array.prototype.getById = function(id){
    var r = null;
    for(var i=0;i<this.length;i++){
        if(this[i].id == id){
            r = this[i];
            break;
        }
    }
    return r;
};

Array.prototype.hasById = function(id){
    for(var i=0;i<this.length;i++){
        if(this[i].id == id){
            return true;
        }
    }
    return false;
};

Array.prototype.rmById = function(id){
    var r = null;
    for(var i=0;i<this.length;i++){
        if(this[i].id == id){
            this.splice(i,1);
            break;
        }
    }
    return r;
};