/**
 * Created by emaxe on 10.07.2015.
 */
//if(app==undefined){
//    var app = angular.module('app', []);
//}

app.controller('mainCtrl', function($scope, app){
    console.log('mainCtrl is ready!');
    var that = this;
    $scope.app = app;

    this.init = function(){

    };

    that.init();

    console.log('mainCtrl is end!');

});