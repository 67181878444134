/**
 * Created by User on 06.09.2016.
 */

app.directive('calendarDayType', function () {
    return {
        restrict: 'A',
        link: function (scope, e, attrs) {
            attrs.$observe('calendarDayType', function (value) {
                //console.log('.', value);

                if(value!='') value = JSON.parse(value);
                //console.log('.', value);
                if (value != undefined && value.length > 0) {

                    var type = value[0];

                    if (value.length > 1) {

                        var c = 0;

                        value.forEach(function (e, i, a) {
                            if (e.index > type.index) type = e;
                            if(e.multi) c++;
                        });

                        for(var i=0;i<value.length;i++){
                            if (value[i].index > type.index) type = value[i];
                            if(+value[i].multi) c++;
                        }

                        if(c>1) e[0].classList.add('multi');
                    } else {
                        e[0].classList.remove('multi');
                    }

                    if (type.bg_color != null) {
                        e[0].style.background = type.bg_color;
                    } else {
                        e[0].style.background = 'transparent';
                    }

                } else {
                    e[0].style.background = 'transparent';
                    e[0].classList.remove('multi');

                }
            });
        }
    }
});