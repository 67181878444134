/**
 * Created by emaxe on 19.08.2016.
 */

app.factory('Storage', function(){
    var that = this;

    this.fLoad = false;

    this.jsLoadArray = [];

    this.dayName = [
      'Нулевой день',
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота',
      'Воскресенье'
    ];


    this.meta = null;
    this.menu = null;

    this.journalCtrl = {};

    this.settingsCtrl = {};

    this.yearSheduleCtrl = {};

    this.sheduleCtrl = {};
    this.sheduleListCtrl = {};
    this.sheduleEditCtrl = {};

    this.reportCtrl = {};
    this.statCtrl = {};
    this.profileCtrl = {};

    return this;
});