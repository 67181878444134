/**
 * Created by emaxe on 17.08.2016.
 */

app.factory('Config',function(){
    var that = this;

    this.meta = {};

    this.meta.token = $('meta[name=_token]').attr('content');
    //this.meta.host = $('meta[name=_app_host]').attr('content');
    //this.meta.userRole = $('meta[name=_user_role]').attr('content');


    var metas = document.getElementsByTagName('meta');
    for (var i=0; i<metas.length; i++) {
        var name = metas[i].getAttribute("name");
        if(name!=null){
            that.meta[name] = metas[i].getAttribute("content");
        }
    }

    return this;
});

app.factory('User',function(rUser){
    var that = this;

    this.user = null;

    rUser.current(function(data){
        that.user = data.item;
        console.info('rUser.current - item', data);
    });

    return this;
});