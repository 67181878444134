/**
 * Created by Emaxe on 31.08.2016.
 */

app.directive('ngEnter', function () {
    return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
            if(event.which === 13) {
                setTimeout(function(){
                    scope.$apply(function (){
                        scope.$eval(attrs.ngEnter);
                    });
                },10);
                event.preventDefault();
            }
        });
    };
});