/**
 * Created by emaxe on 14.07.2015.
 */
app.factory('helper', function($mdToast, $mdDialog, app, Storage, toastr){
    var that = this;

    this.httpChState = function(data, f){
        if(data.state != undefined && data.state == -401){
            location.reload();
        }else{
            if(typeof (f) == 'function') f();
        }
    };

    this.splitHash = function(){
        var hash = location.hash;
        var reg = /#\/?(\w*)\/?(\w*)?\/?(\w*)?\/?(\w*)?\/?(\w*)?\/?(\w*)?\/?(\w*)?\/?(\w*)?/;
        return hash.match(reg);
    };

    this.showToast = function(o){
        console.log('> TOASTR', o);
        if(o.text!=undefined){

            if(o.type==undefined) o.type='info';
            if(o.title==undefined) o.title='';

            if(o.type=='info'){
                toastr.info(o.text, o.title);
            }
            if(o.type=='success'){
                toastr.success(o.text, o.title);
            }
            if(o.type=='error'){
                toastr.error(o.text, o.title);
            }
            if(o.type=='warning'){
                toastr.warning(o.text, o.title);
            }
        }else{
            toastr.info(o);
        }
    };

    this.get = function(data,def){
        if(data==undefined){
            if(def==undefined) return undefined;
            return def;
        }else{
            return data;
        }
    };

    this.print = function(elem, title, styles, dafaultStyles){

        var dStyles = [
            'css/app.css',
            'css/angular.css',
            'css/bootstrap.css',
            'fa.css',
            'libs.css',
            'ngMaterial.css'
        ];

        if(dafaultStyles==undefined) dafaultStyles=false;
        if(styles==undefined){
            styles = dStyles;
        }else{
            if(isArray(styles)){
                dStyles.forEach(function(e){
                    styles.push(e);
                })
            }
        }
        // console.log(elem);
        var mywindow = window.open('', 'PRINT', 'height=400,width=600');

        mywindow.document.write('<html><head><title>' + title  + '</title>');

        styles.forEach(function(e,a,i){
            mywindow.document.write("<link rel=\"stylesheet\" href=\""+e+"\">");
        });

        // mywindow.document.write("<link rel=\"stylesheet\" href=\"css/app.css\">");
        // mywindow.document.write("<link rel=\"stylesheet\" href=\"css/angular.css\">");
        // mywindow.document.write("<link rel=\"stylesheet\" href=\"css/bootstrap.css\">");
        // mywindow.document.write("<link rel=\"stylesheet\" href=\"css/fa.css\">");
        // mywindow.document.write("<link rel=\"stylesheet\" href=\"css/libs.css\">");
        // mywindow.document.write("<link rel=\"stylesheet\" href=\"css/ngMaterial.css\">");

        mywindow.document.write('</head><body >');

        mywindow.document.write('<div class=\"panel panel-default\">');
        mywindow.document.write('<div class=\"panel-heading\">' + title  + '</div>');
        mywindow.document.write('<div class=\"panel-body\">');
        mywindow.document.write(elem.innerHTML);
        mywindow.document.write('</div></div>');

        // mywindow.document.write('<h1>' + title  + '</h1>');
        // mywindow.document.write(elem.innerHTML);
        // mywindow.document.write('</body></html>');
        mywindow.onload = function() {
            mywindow.print();
            mywindow.close();
        };

        setTimeout(function(){
            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/
            setTimeout(function(){
                // mywindow.print();
                // mywindow.close();
            }, 1000);
        }, 100);

        //
        //
        // mywindow.print();
        // mywindow.close();
    };

    this.isArrayTrue = function(arr){
        var f = true;
        for(var i=0;i<arr.length;i++){
            if(arr[i]!=true){
                f = false;
                break;
            }
        }
        return f;
    };

    this.isObjectTrue = function(arr){
        var f = true;
        for(var key in arr){
            if(arr[key]!=true){
                f = false;
                break;
            }
        }
        return f;
    };

    this.setArray = function(arr, val){
        for(var i=0;i<arr.length;i++){
            arr[i] = val;
        }
        return arr;
    };

    this.setObject = function(arr, val){
        for(var key in arr){
            arr[key] = val
        }
        return arr;
    };

    this.concatObjects = function(o1,o2,replace){
        if(replace==undefined) replace = true;
        for(var i in o2){
            if(o1[i]==undefined || (o1[i]!=undefined && replace)){
                o1[i] = o2[i];
            }
        }
    };

    this.translit = function(word){

        var answer = "";
        var a = {}

        a["Ё"]="YO";a["Й"]="I";a["Ц"]="TS";a["У"]="U";a["К"]="K";a["Е"]="E";a["Н"]="N";a["Г"]="G";a["Ш"]="SH";a["Щ"]="SCH";a["З"]="Z";a["Х"]="H";a["Ъ"]="'";
        a["ё"]="yo";a["й"]="i";a["ц"]="ts";a["у"]="u";a["к"]="k";a["е"]="e";a["н"]="n";a["г"]="g";a["ш"]="sh";a["щ"]="sch";a["з"]="z";a["х"]="h";a["ъ"]="'";
        a["Ф"]="F";a["Ы"]="I";a["В"]="V";a["А"]="a";a["П"]="P";a["Р"]="R";a["О"]="O";a["Л"]="L";a["Д"]="D";a["Ж"]="ZH";a["Э"]="E";
        a["ф"]="f";a["ы"]="i";a["в"]="v";a["а"]="a";a["п"]="p";a["р"]="r";a["о"]="o";a["л"]="l";a["д"]="d";a["ж"]="zh";a["э"]="e";
        a["Я"]="Ya";a["Ч"]="CH";a["С"]="S";a["М"]="M";a["И"]="I";a["Т"]="T";a["Ь"]="'";a["Б"]="B";a["Ю"]="YU";
        a["я"]="ya";a["ч"]="ch";a["с"]="s";a["м"]="m";a["и"]="i";a["т"]="t";a["ь"]="'";a["б"]="b";a["ю"]="yu";

        for (i = 0; i < word.length; ++i){

            answer += a[word[i]] === undefined ? word[i] : a[word[i]];
        }
        return answer;
    };

    this.confirm = function(o){
        if(o.yes==undefined){
            o.yes = {};
        }
        if(o.no==undefined){
            o.no = {};
        }
        if (o.title==undefined){
            o.title = 'Подтвердите действие';
        }
        if (o.text==undefined){
            o.text = 'Точно?';
        }
        if (o.yes.title==undefined){
            o.yes.title = 'Да';
        }
        if (o.no.title==undefined){
            o.no.title = 'Нет';
        }
        if (o.event==undefined){
            o.event = angular.element(document.body);
        }

        if (o.yes.onClick==undefined || typeof(o.yes.onClick)!='function'){
            o.yes.onClick = function(event){
                console.log('Yes!!');
            };
        }

        if (o.no.onClick==undefined || typeof(o.no.onClick)!='function'){
            o.no.onClick = function(event){
                console.log('Noooo!!');
            };
        }

        var confirm = $mdDialog.confirm()
            .parent(angular.element(document.body))
            .title(o.title)
            .content(o.text)
            .ariaLabel(o.title)
            .ok(o.yes.title)
            .cancel(o.no.title)
            .targetEvent(o.event)
            .clickOutsideToClose(true);
        $mdDialog.show(confirm).then(function() {
            o.yes.onClick();
        }, function() {
            o.no.onClick();
        });


    };

    this.prompt = function(o){
        that.promt(o);
    };

    this.promt = function(o){

        if(o.yes==undefined){
            o.yes = {};
        }
        if(o.no==undefined){
            o.no = {};
        }
        if (o.title==undefined){
            o.title = 'Введите значение';
        }
        if (o.text==undefined){
            o.text = '';
        }
        if (o.initialValue==undefined){
            o.initialValue = '';
        }
        if (o.yes.title==undefined){
            o.yes.title = 'Подтвердить';
        }
        if (o.no.title==undefined){
            o.no.title = 'Отмена';
        }
        if (o.event==undefined){
            o.event = angular.element(document.body);
        }

        if (o.yes.onClick==undefined || typeof(o.yes.onClick)!='function'){
            o.yes.onClick = function(event){
                console.log('Yes!!');
            };
        }

        if (o.no.onClick==undefined || typeof(o.no.onClick)!='function'){
            o.no.onClick = function(event){
                console.log('Noooo!!');
            };
        }

        var confirm = $mdDialog.prompt()
            .title(o.title)
            .textContent(o.text)
            .initialValue(o.initialValue)
            .targetEvent(o.ev)
            .ok(o.yes.title)
            .cancel(o.no.title);

        $mdDialog.show(confirm).then(function(res) {
            o.yes.onClick(res)
        }, function() {
            o.no.onClick()
        });


    };

    this.showDialog = function(o){
        if(o.ev == undefined){
            o.ev = angular.element(document.body);
        }
        app.getJS(o.model+'/dialogs/'+o.name, function(){
            $mdDialog.show({
                    controller: 'DialogCtrl',
                    clickOutsideToClose: true,
                    templateUrl: 'ng-tmp/'+ o.model+'/'+ o.tName,
                    parent: angular.element(document.body),
                    targetEvent: o.ev,
                    locals: {
                        InScope: o.inScope
                    }
                })
                .then(function(data) {
                    if(data.state=='ok'){
                        if(typeof(o.success)=='function'){
                            o.success(data);
                        }
                    }else{
                        if(typeof(o.error)=='function'){
                            o.error(data);
                        }
                    }
                }, function() {
                    if(typeof(o.cancel)=='function'){
                        o.cancel();
                    }
                });
        });
    };


    return this;
});


app.factory('InScope', function(){
    return this;
});