/**
 * Created by User on 28.08.2016.
 */

app.controller('DialogCtrl', function($scope, $mdDialog, Storage, InScope){
    console.log('dialogCtrl is ready!');
    var that = this;
    $scope.app = app;
    $scope.Storage = Storage;
    $scope.InScope = InScope;

    Storage.dialogSubHeader = '';

    $scope.cancel = function() {
        $mdDialog.cancel();
    };

});